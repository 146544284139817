<template>
  <div class="sectionWrap">
    <p class="sectionTitle">
      <b></b>
      <span>{{ list.title }}</span>
      <b></b>
    </p>
    <p class="sectionInfo">
      {{ list.description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    list: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang=""></style>

export function objKeySort(obj) { //对象排序
    var newkey = Object.keys(obj).sort();
    var newObj = {};
    for (var i = 0; i < newkey.length; i++) {
        newObj[newkey[i]] = obj[newkey[i]];
    }
    return newObj;
};
var sk = window.sessionStorage.getItem('sk')

export function pinjie(obj) { //对象拼接转成数组
    var sign = []
    for (var i in obj) {
        if (obj[i] === '' || obj[i] == undefined || obj[i] == null) {
            delete obj[i]
        } else {
            if(sk){
                sign.push(sk)
            }else{
                sign.push('AWDm5zIME3UOoDLi')
            }
            sign.push(i)
            if (typeof obj[i] == 'object') {
                sign.push(JSON.stringify(obj[i]))
            } else {
                sign.push(obj[i])
            }
            if(sk){
                sign.push(sk)
            }else{
                sign.push('AWDm5zIME3UOoDLi')
            }
        }
    }
    return sign;
}

export function changeStr(arr) { //数组转字符串拼接（不要逗号）
    var str = ''
    arr.forEach(item => {
        str += item
    })
    return str
}

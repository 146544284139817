<template>
  <!-- 单选 -->
  <div class="radioWrap" :id="'question' + list.question_seq">
    <b class="itemNum">{{ list.question_seq }}</b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
          <span class="detailMate" v-if="is_stuff == 1">资料</span>
          <span class="detailCate">单选题</span>
          <span class="detailScore" v-if='list.score'>[{{list.score}}分]</span>
        </div>
        <div class="detailContent" v-html="list.title"></div>
      </div>
      <div class="detailOption">
        <el-radio-group v-model="list.radio">
          <el-radio
            :label="item.option_mark"
            v-for="(item, index) in list.options"
            :key="index"
          >
            <i class="optionIcon"></i>
            {{ item.option_mark }} <div class="optionContent" v-html="item.option_content"></div>
          </el-radio>
        </el-radio-group>
      </div>
<!--      <Point :thought_dial="list.thought_dial" v-if="list.thought_dial"></Point>-->
    </div>
    <i
      class="el-icon-star-on itemGreyStar"
      v-if="list.iscollect == 0"
      @click="collect(0)"
    ></i>
    <i
      class="el-icon-star-on itemYellowStar"
      v-if="list.iscollect == 1"
      @click="collect(1)"
    ></i>
  </div>
</template>

<script>
import { updatecollect,delcollect } from "../assets/api";
// import Point from "./com/Point.vue"; //思路点拨
export default {
  props: {
    list: Object,
    is_stuff: Number,
  },
  components: {
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    collect(type) {
      if(type==0){
        let params = {
          column_id: sessionStorage.getItem('column_id'),
          collecttype: 1,
          question_paper_id: this.list.question_id
        };
        updatecollect(params).then((res) => {
          if (res.code==0){
            this.list.collect_id=res.data.collect_id;
            this.list.iscollect=1;
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          }else {
            this.$message.error("收藏失败");
          }
        });
      }else {
        let params = {
          column_id: sessionStorage.getItem('column_id'),
          collecttype: 1,
          collect_id: this.list.collect_id
        };
        delcollect(params).then((res) => {
          if (res.code==0){
            this.list.collect_id=0;
            this.list.iscollect=0;
            this.$message({
              message: "取消收藏成功",
              type: "success",
            });
          } else {
            this.$message.error("取消收藏失败");
          }
        });
      }
    }
  },
};
</script>

<style scoped lang=""></style>

<template>
  <!-- 真题作战作答 -->
  <div class="questionsWrap">
    <header>
      <div class="headerWrap">
        <div class="headerTitle">
          <i class="el-icon-arrow-left" @click="save"></i>
          {{ Parper.title }}
        </div>
        <div class="headerWrapcz">
          <a
            class="headerLoad cursorP headerWrapsc"
            v-if="area_type == 2 || area_type == 3"
            @click="collectPaper()"
          >
            <i
              class="iconfont"
              :class="iscollect == 0 ? 'icon-kcpj' : 'icon-qxsc on'"
            ></i>
            {{ iscollect == 0 ? "收藏" : "已收藏" }}
          </a>
          <!--          <a-->
          <!--            class="headerLoad cursorP"-->
          <!--            :href="questionData.download_url"-->
          <!--            target="_blank"-->
          <!--          >-->
          <!--            <i class="el-icon-download"></i>-->
          <!--            下载-->
          <!--          </a>-->
        </div>
      </div>
    </header>
    <main>
      <div class="mainWrap" id="mainWrap">
        <div class="questionstishi" v-if="area_type == 2 || area_type == 3">
          <dl v-if="papermainObj.note != ''">
            <dt class="fontSize18 lineH34">
              <i class="iconfont icon-uxbicon_matters fontSize24 lineH34"></i
              >注意事项
            </dt>
            <dd class="fontSize16 lineH32">
              {{ papermainObj.note }}
            </dd>
          </dl>
          <dl v-if="papermainObj.cover_tips != ''">
            <dt class="fontSize18 lineH34">
              <i class="iconfont icon-uxbicon_tips fontSize24 lineH34"></i>提示
            </dt>
            <dd class="fontSize16 lineH32">
              {{ papermainObj.cover_tips }}
            </dd>
          </dl>
        </div>
        <template v-if="bfIshow">
          <div v-for="(item1, index1) in question_infos" :key="index1">
            <!-- 段落 -->
            <Section :list="item1"></Section>
            <div v-for="(item, index) in item1.timu" :key="index">
              <!-- 单选 -->
              <Radio
                class="itemWrap"
                v-if="item.is_stuff == 0 && item.rtype == 1"
                :list="item"
                :is_stuff="item.is_stuff"
              ></Radio>
              <!-- 多选 -->
              <Checkbox
                class="itemWrap"
                v-if="
                  (item.is_stuff == 0 && item.rtype == 2) ||
                  (item.is_stuff == 0 && item.rtype == 3)
                "
                :list="item"
                :is_stuff="item.is_stuff"
              ></Checkbox>
              <!-- 判断 -->
              <Estimate
                class="itemWrap"
                v-if="item.is_stuff == 0 && item.rtype == 5"
                :list="item"
                :is_stuff="item.is_stuff"
              ></Estimate>
              <!-- 论述 -->
              <Discuss
                class="itemWrap"
                v-if="item.is_stuff == 0 && item.rtype == 4"
                :list="item"
                :is_stuff="item.is_stuff"
              ></Discuss>
              <!-- 资料 -->
              <Materials
                class="itemWrap"
                v-if="item.is_stuff == undefined"
                :list="item"
              ></Materials>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="(item, index) in question_infos" :key="index">
            <!-- 单选 -->
            <Radio
              class="itemWrap"
              v-if="item.is_stuff == 0 && item.rtype == 1"
              :list="item"
              :is_stuff="item.is_stuff"
            ></Radio>
            <!-- 多选 -->
            <Checkbox
              class="itemWrap"
              v-if="
                (item.is_stuff == 0 && item.rtype == 2) ||
                (item.is_stuff == 0 && item.rtype == 3)
              "
              :list="item"
              :is_stuff="item.is_stuff"
            ></Checkbox>
            <!-- 判断 -->
            <Estimate
              class="itemWrap"
              v-if="item.is_stuff == 0 && item.rtype == 5"
              :list="item"
              :is_stuff="item.is_stuff"
            ></Estimate>
            <!-- 论述 -->
            <Discuss
              class="itemWrap"
              v-if="item.is_stuff == 0 && item.rtype == 4"
              :list="item"
              :is_stuff="item.is_stuff"
            ></Discuss>
            <!-- 资料 -->
            <Materials
              class="itemWrap"
              v-if="item.is_stuff == undefined"
              :list="item"
            ></Materials>
          </div>
        </template>
      </div>
    </main>
    <footer>
      <AnswerSheet
        :answerTotal="this.question_infos"
        :type="bfIshow"
      ></AnswerSheet>
    </footer>
    <aside>
      <ul>
        <li v-if="Parper.time_limit > 0">
          <i class="iconfont icon-icon_time"></i>
          {{ downTime }}
        </li>
        <li v-else>
          <i class="iconfont icon-icon_time"></i>
          {{ upTime }}
        </li>
        <!-- <li v-if="iscollect == 0" @click="collectPaper(0)">
                    <i class="el-icon-star-off"></i>
                    收藏试卷
                </li>
                <li v-else @click="collectPaper(1)">
                    <i class="el-icon-star-on"></i>
                    取消收藏
                </li> -->
        <li @click="save">
          <i class="iconfont icon-icon_next"></i>
          下次做
        </li>
        <li @click="submit">
          <i class="iconfont icon-icon_jj"></i>
          交卷
        </li>
      </ul>
    </aside>
    <DiaLogs ref="DiaLogs"></DiaLogs>
  </div>
</template>

<script>
import {
  sample,
  papermain,
  paperinfo,
  updatecollect,
  delcollect,
  weakspot,
} from "./assets/api";
import Section from "./components/Section.vue"; //段落
import Radio from "./components/Radio"; // 单选
import Checkbox from "./components/Checkbox"; // 多选
import Estimate from "./components/Estimate"; // 判断
import Discuss from "./components/Discuss"; // 论述
import Materials from "./components/Materials"; // 材料
import AnswerSheet from "./components/com/AnswerSheet.vue"; //答题卡
import DiaLogs from "../components/DiaLog/DiaLogs.vue"; //弹窗
export default {
  name: "SearchRespond",
  components: {
    Section,
    Radio,
    Checkbox,
    Estimate,
    Discuss,
    Materials,
    AnswerSheet,
    DiaLogs,
  },
  data() {
    return {
      question_infos: [],
      questionData: {},
      num: "",
      seconds: 0,
      upTime: "",
      downTime: "",
      iscollect: "",
      bfIshow: true,
      answerTotal: [],
      area_type: "",
      newtime: "",
      Question_list: [],
      extar: {},
      Parper: "",
      papermainObj: {},
      collect_id: "",
    };
  },
  mounted() {
    this.area_type = this.$route.query.area_type;
    if (this.area_type == 1) {
      //  专区刷题
      this.getList();
    } else if (
      this.area_type == 2 ||
      this.area_type == 3 ||
            this.area_type == 5||
      this.area_type == 0
    ) {
      //  2  考试  3  练题  0 课件目录
      this.getpaperList();
    } else if (this.area_type == 4) {
      //   弱项
      this.weakspot();
    }
  },
  methods: {
    //数据处理
    dataProcessing(a, b, c, d) {
      a.map((item) => {
        if (item.rtype == 1 || item.rtype == 5 || item.rtype == 4) {
          this.$set(item, "radio", "");
          if (item.user_answer != "") {
            this.$set(item, "radio", item.user_answer);
          }
        } else if (item.rtype == 2 || item.rtype == 3) {
          this.$set(item, "radio", []);
          if (item.user_answer != "") {
            var ss = item.user_answer.split(",");
            this.$set(item, "radio", ss);
          }
        }
        // this.Question_list.push(item);
        this.$nextTick(() => {
          if (item.is_stuff > 0) {
            c.map((item1) => {
              if (item1.stuff_id == item.stuff_id) {
                if (!item1.list) {
                  this.$set(item1, "list", []);
                }
                this.$set(item1, "section_id", item.section_id);
                item1.list.push(item);
                b.push(item1);
                if (b.length > 0) {
                  b = this.unique(b);
                }
              }
            });
          } else {
            b.push(item);
          }
        });
        this.$nextTick(() => {
          if (d != undefined && d.length > 0) {
            d.map((itembf) => {
              this.$set(itembf, "timu", []);
              b.map((item) => {
                if (itembf.section_id == item.section_id) {
                  itembf.timu.push(item);
                }
              });
            });
            this.question_infos = d;
            this.bfIshow = true;
          } else {
            this.question_infos = b;
            this.bfIshow = false;
          }
        });

      });
    },
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].is_stuff == undefined) {
            if (arr[i].stuff_id == arr[j].stuff_id) {
              arr.splice(j, 1);
              j--;
            }
          }
        }
      }
      return arr;
    },
    //获取试题-考点组题
    getList() {
      let params = {
        special_id: this.$route.query.special_id,
        column_id: sessionStorage.getItem("column_id"),
        catalogue_id: this.$route.query.catalogue_id,
        num: sessionStorage.getItem("num"),
        smodel: sessionStorage.getItem("smodel"),
        answer_id: this.$route.query.answer_id,
      };
      sample(params).then((res) => {
        if (res.code == 0) {
          if (res.data.Question_list.length > 0) {
            let b = [];
            this.newtime = res.data.Newtime;
            this.extar = res.data.Extar;
            this.Parper = res.data.Paper;
            this.Question_list = res.data.Question_list;
            this.dataProcessing(
              res.data.Question_list,
              b,
              res.data.Stuffinfos,
              res.data.Sectioninfos
            );
            setTimeout(function () {
              if (res.data.Answer_seq != "") {
                let t = document.querySelector(
                  "#question" + res.data.Answer_seq
                ).offsetTop;
                window.scrollTo(0, t - 88);
              }
            }, 1000);
            if (this.Parper.time_limit > 0) {
              this.downSec = res.data.Durationtime;
              this.timer = setInterval(this.endTimer, 1000);
            } else {
              this.timer2 = setInterval(this.startTimer, 1000);
            }
          } else {
            this.$message.error("暂无试题");
          }
        }
      });
    },
    //获取试题-试卷
    getpaperList() {
      var a={}
      if(this.area_type == 3||this.area_type == 2||this.area_type == 5){
        a = {
          column_id: sessionStorage.getItem("column_id"),
          special_id:
            this.$route.query.special_id == undefined ||
            this.$route.query.special_id == ""
              ? "0"
              : this.$route.query.special_id,
        };
        if (this.area_type == 3) {
          a.exam_id = this.$route.query.exam_id;
        }
      } else if (this.area_type == 0) {
        a = {
          course_id: this.$route.query.course_id,
          lesson_id: this.$route.query.lesson_id,
        };
      }
      let params = {
        extar: JSON.stringify(a),
        area_type: this.area_type,
        paper_id: this.$route.query.paper_id,
        answer_id: this.$route.query.answer_id,
      };
      papermain(params).then((re) => {
        if (re.code == 0) {
          this.papermainObj = re.data;
          let par = {
            extar: JSON.stringify(re.data.extar),
            show_status: 0,
            paper_id: this.$route.query.paper_id,
            answer_id: this.$route.query.answer_id,
          };
          paperinfo(par).then((res) => {
            if (res.code == 0) {
              if (res.data.Question_list.length > 0) {
                let b = [];
                this.newtime = res.data.Newtime;
                this.extar = res.data.Extar;
                this.Parper = res.data.Paper;
                this.Question_list = res.data.Question_list;
                this.iscollect = res.data.iscollect;
                this.collect_id = res.data.collect_id;
                this.dataProcessing(
                  res.data.Question_list,
                  b,
                  res.data.Stuffinfos,
                  res.data.Sectioninfos
                );
                setTimeout(function () {
                  if (res.data.Answer_seq != "") {
                    let t = document.querySelector(
                      "#question" + res.data.Answer_seq
                    ).offsetTop;
                    window.scrollTo(0, t - 88);
                  }
                }, 1000);
                if (this.Parper.time_limit > 0) {
                  this.downSec = res.data.Durationtime;
                  this.timer = setInterval(this.endTimer, 1000);
                } else {
                  this.timer2 = setInterval(this.startTimer, 1000);
                }
              } else {
                this.$message.error("暂无试题");
              }
            }
          });
        }
      });
    },
    //获取试题-弱项组题
    weakspot() {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        subject_id: this.$route.query.subject_id,
        answer_id: this.$route.query.answer_id,
      };
      weakspot(params).then((res) => {
        if (res.code == 0) {
          if (res.data.Question_list.length > 0) {
            let b = [];
            this.newtime = res.data.Newtime;
            this.extar = res.data.Extar;
            this.Parper = res.data.Paper;
            this.Question_list = res.data.Question_list;
            this.dataProcessing(
              res.data.Question_list,
              b,
              res.data.Stuffinfos,
              res.data.Sectioninfos
            );
            setTimeout(function () {
              if (res.data.Answer_seq != "") {
                let t = document.querySelector(
                  "#question" + res.data.Answer_seq
                ).offsetTop;
                window.scrollTo(0, t - 88);
              }
            }, 1000);
            if (this.Parper.time_limit > 0) {
              this.downSec = res.data.Durationtime;
              this.timer = setInterval(this.endTimer, 1000);
            } else {
              this.timer2 = setInterval(this.startTimer, 1000);
            }
          } else {
            this.$message.error("暂无试题");
          }
        }
      });
    },
    //收藏试卷
    collectPaper() {
      if (this.iscollect == 0) {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 2,
          question_paper_id: this.$route.query.paper_id,
        };
        updatecollect(params).then((res) => {
          if (res.code == 0) {
            this.iscollect = 1;
            this.collect_id = res.data.collect_id;
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          } else {
            this.$message.error("收藏失败");
          }
        });
      } else {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 2,
          collect_id: this.collect_id,
        };
        delcollect(params).then((res) => {
          if (res.code == 0) {
            this.iscollect = 0;
            this.$message({
              message: "取消收藏成功",
              type: "success",
            });
          } else {
            this.$message.error("取消收藏失败");
          }
        });
      }
    },
    //计时器
    startTimer() {
      this.seconds += 1;
      let m =
        Math.floor((this.seconds / 60) % 60) < 10
          ? "0" + Math.floor((this.seconds / 60) % 60)
          : Math.floor((this.seconds / 60) % 60);
      let s =
        this.seconds % 60 < 10
          ? "0" + Math.floor(this.seconds % 60)
          : Math.floor(this.seconds % 60);
      this.upTime = m + ":" + s;
    },
    endTimer() {
      this.downSec -= 1;
      let m =
        Math.floor(this.downSec / 60) < 10
          ? "0" + Math.floor(this.downSec / 60)
          : Math.floor(this.downSec / 60);
      let s =
        this.downSec % 60 < 10
          ? "0" + Math.floor(this.downSec % 60)
          : Math.floor(this.downSec % 60);
      this.downTime = m + ":" + s;
      if (this.downSec == 3) {
        clearTimeout(this.timer);
        clearTimeout(this.timer2);
        //type, diaData, answerType, tabindex, durationTime
        let b = this.Question_list;
        let ind;
        b.findIndex((item, index) => {
          if (
            (item.rtype == 1 || item.rtype == 5 || item.rtype == 4) &&
            item.radio
          ) {
            ind = index;
          }
          if ((item.rtype == 2 || item.rtype == 3) && item.radio.length > 0) {
            ind = index;
          }
        });
        let tabindex;
        if (ind) {
          tabindex = b[ind].question_seq;
        } else {
          tabindex = this.Question_list[0].question_seq;
        }
        this.$refs.DiaLogs.openInit(
          5,
          this.Question_list,
          this.newtime,
          this.extar,
          tabindex
        ); //考试时间到
      }
    },
    //下次做
    save() {
      let a = this.Question_list;
      let ind;
      a.findIndex((item, index) => {
        if (
          (item.rtype == 1 || item.rtype == 5 || item.rtype == 4) &&
          item.radio
        ) {
          ind = index;
        }
        if ((item.rtype == 2 || item.rtype == 3) && item.radio.length > 0) {
          ind = index;
        }
      });
      let tabindex;
      if (ind) {
        tabindex = a[ind].question_seq;
      } else {
        tabindex = this.Question_list[0].question_seq;
      }
      //type, diaData, answerType, tabindex, durationTime
      this.$refs.DiaLogs.openInit(
        4,
        this.Question_list,
        this.newtime,
        this.extar,
        tabindex
      );
    },
    //交卷
    submit() {
      // let c = this.question_infos.filter((item)=>{
      //   if(item.stuff_id){return item.stuff_id!=0}
      //
      // })
      //  let d;
      //  console.log(c,1234)
      // if(c.length>0){
      // c.map((item)=>{
      //   d=item.list.every((item) => {
      //     return (
      //       ((item.rtype == 1 || item.rtype == 5 || item.rtype == 4) &&
      //         item.radio) ||
      //       ((item.rtype == 2 || item.rtype == 3) && item.radio.length > 0)
      //     );
      //   });
      // })}
      // console.log(this.question_infos,8888)
      let a =  this.Question_list.every((item) => {
        return (
          ((item.rtype == 1 || item.rtype == 5 || item.rtype == 4) &&
            item.radio) ||
          ((item.rtype == 2 || item.rtype == 3) && item.radio.length > 0)
        );
      });
      console.log(this.Question_list,9999)
      let b = this.Question_list;
      let ind;
      b.findIndex((item, index) => {
        if (
          (item.rtype == 1 || item.rtype == 5 || item.rtype == 4) &&
          item.radio
        ) {
          ind = index;
        }
        if ((item.rtype == 2 || item.rtype == 3) && item.radio.length > 0) {
          ind = index;
        }
      });
      let tabindex;
      if (ind) {
        tabindex = b[ind].question_seq;
      } else {
        tabindex = this.Question_list[0].question_seq;
      }
      if (a) {
        this.$refs.DiaLogs.openInit(
          6,
          this.Question_list,
          this.newtime,
          this.extar,
          tabindex
        );
      } else {
        this.$refs.DiaLogs.openInit(
          1,
          this.Question_list,
          this.newtime,
          this.extar,
          tabindex
        );
      }
    },
  },
  destroyed() {
    if (this.Parper.time_limit > 0) {
      clearTimeout(this.timer);
    } else {
      clearTimeout(this.timer2);
    }
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <div v-if="diaShow">
    <div class="diabox"></div>
    <div class="diamsg">
      <div v-if="type == 1">
        <h2>
          您还有未作答的题目
          <br />
          是否交卷？
        </h2>
        <ul>
          <li class="fl kong" @click="cancel">继续作答</li>
          <li class="fr shi" @click="submit(1)">确定交卷</li>
        </ul>
      </div>
      <div v-if="type == 6">
        <h2>确认交卷？</h2>
        <ul>
          <li class="fl kong" @click="cancel">继续作答</li>
          <li class="fr shi" @click="submit(1)">确定交卷</li>
        </ul>
      </div>
      <div v-if="type == 2">
        <h2>交卷失败，请重试</h2>
        <ul>
          <li class="cent shi" @click="submit(1)">重试</li>
        </ul>
      </div>
      <div v-if="type == 3">
        <h2>交卷成功</h2>
        <p>页面将在 {{ diaTimeSuccess }} 秒后自动跳转</p>
        <ul>
          <li class="cent shi" @click="jump">立即跳转</li>
        </ul>
      </div>
      <div v-if="type == 4">
        <h2>确定要离开吗？</h2>
        <p>将为您自动保存作答进度</p>
        <ul>
          <li class="fl kong" @click="submit(2)">离开</li>
          <li class="fr shi" @click="cancel">留下</li>
        </ul>
      </div>
      <div v-if="type == 5">
        <h2>考试时间到，{{ diaTimeEnd }}秒后强制交卷</h2>
        <ul>
          <li class="cent shi" @click="submit(1)">立即交卷</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { submitAnswer } from "./assets/api";

export default {
  data() {
    return {
      type: "",
      diaShow: false,
      diaData: {},
      tabindex: "",
      durationTime: "",
      diaTimeSuccess: 3,
      timer: "",
      diaTimeEnd: 3,
      timer2: "",
      answer_id: "",
      extarobj: {},
    };
  },
  destroyed() {
    if (this.type == 3) {
      clearTimeout(this.timer);
    }
    if (this.type == 5) {
      clearTimeout(this.timer2);
    }
  },
  methods: {
    /*
     * type 1还有未作答弹窗 2交卷失败弹窗 3成功跳转弹窗 4离开弹窗  5强制交卷弹窗  6确认交卷
     * data 数据
     * durationTime 答题时长
     * tabindex 交卷时的当前题号
     */
    openInit(type, diaData, durationTime, extar, tabindex) {
      this.diaShow = true;
      this.type = type;
      this.diaData = diaData;
      this.durationTime = durationTime;
      this.tabindex = tabindex;
      this.extarobj = extar;
      if (type == 3) {
        this.timer = setInterval(this.jumpTimer, 1000);
      }
      if (type == 5) {
        this.timer2 = setInterval(this.endTimer, 1000);
      }
    },
    //跳转计时器
    jumpTimer() {
      this.diaTimeSuccess -= 1;
      if (this.diaTimeSuccess == 0) {
        clearTimeout(this.timer);
        this.jump();
      }
    },
    //强制交卷计时器
    endTimer() {
      this.diaTimeEnd -= 1;
      if (this.diaTimeEnd == 0) {
        clearTimeout(this.timer2);
        this.submit(1);
      }
    },
    //取消
    // 继续做答 留下
    cancel() {
      this.diaShow = false;
    },
    //交卷
    // 1 确定交卷，立即交卷    2 离开  3 重试
    submit(type) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      let params = {
        answer_id: this.$route.query.answer_id, //已作答的话作答id
        paper_id: this.$route.query.paper_id ? this.$route.query.paper_id : "0", //试卷ID，没有传0
        answer_seq: this.tabindex, //交卷时的当前题号(保存时必传，交卷时可不传) （question_list下标）
        answer_status: type == 1 ? 1 : 0, //1代表交卷 0代表保存
        area_type: this.$route.query.area_type, //专区类型0默认 1考点刷题 2试卷练习 3模拟考试 4弱项 5收藏
        start_time: this.durationTime, //开始答卷时间（时间戳）
        extar: JSON.stringify(this.extarobj), //扩展字段 json title exam_id areaid subject point
        paper_submit: "", //作答格式
        chang_img: "", //新增修改图片
      };
      let obj = {};
      let key = "";
      let value = "";
      let chang_img = [];
      this.diaData.forEach((item) => {
        if (item.is_stuff != undefined) {
          if (item.rtype == 1 || item.rtype == 5) {
            if (item.radio) {
              key =
                item.rtype + "_" + item.question_id + "_" + item.question_seq;
              value = item.radio;
              obj[key] = value;
            } else {
              key =
                item.rtype + "_" + item.question_id + "_" + item.question_seq;
              value = "";
              obj[key] = value;
            }
          }
          if (item.rtype == 2 || item.rtype == 3) {
            if (item.radio.length > 0) {
              key =
                item.rtype + "_" + item.question_id + "_" + item.question_seq;
              value = item.radio.toString();
              obj[key] = value;
            } else {
              key =
                item.rtype + "_" + item.question_id + "_" + item.question_seq;
              value = "";
              obj[key] = value;
            }
          }
          if (item.rtype == 4) {
            if (item.chang_img && item.chang_img.length > 0) {
              item.chang_img.map((i) => chang_img.push(i));
            }
            let arr = [];
            if (item.user_pic.length) {
              arr = item.user_pic.map((i) => i.path);
            }
            let arr1 = [];
            arr.map((item) => {
              if (item != undefined) {
                arr1.push(item);
              }
            });
            key = item.rtype + "_" + item.question_id + "_" + item.question_seq;
            value = {
              content: item.radio,
              pic: item.user_pic.length > 0 ? arr1.toString() : "",
            };
            obj[key] = value;
          }
        }
      });
      params.paper_submit = JSON.stringify(obj);
      params.chang_img = '['+chang_img.toString()+']';
      submitAnswer(params).then((res) => {
        if (res.code == 0) {
          if (type == 1) {
            this.type = 3;
            this.answer_id = res.data.answer_id;
            clearTimeout(this.timer2);
            // loading.close();
            setTimeout(() => {
              this.timer = setInterval(this.jumpTimer, 1000);
            }, 30);
          } else {
            // loading.close();
            setTimeout(() => {
              this.$router.go(-1);
            }, 30);
          }
        } else {
          if (type == 1) {
            this.type = 2;
          } else {
            this.$message.error("保存失败");
          }
        }
      });
    },
    //立即跳转
    jump() {
      this.$router.push({
        path: "/paper_report",
        query: {
          answer_id: this.answer_id,
          paper_id: this.$route.query.paper_id
            ? this.$route.query.paper_id
            : "0",
          area_type: this.$route.query.area_type,
          special_id: this.$route.query.special_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.diabox {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 999;
}
.diamsg {
  width: 317px;
  height: 215px;
  padding-top: 85px;
  border-radius: 20px;
  background: url("./assets/images/dailog.png") no-repeat 34px center #fff;
  padding-left: 243px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -150px 0 0 -250px;
  z-index: 999;
  h2 {
    width: 254px;
    text-align: center;
    font-size: 20px;
    line-height: 34px;
  }
  p {
    width: 254px;
    text-align: center;
    font-size: 16px;
    line-height: 34px;
    color: #666;
  }
  ul {
    width: 254px;
    padding-top: 20px;
    li {
      width: 120px;
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      border-radius: 20px;
    }
    .kong {
      border: 1px solid #e2e2e2;
      color: #666;
      &:hover{
      background: #F8F8F8;
      }
    }
    .shi {
      border: 1px solid var(--change-color);
      color: #fff;
      background: var(--change-color);
      &:hover{
        border: 1px solid var(--change-hovercolor);
      background: var(--change-hovercolor);
      }
    }
    .cent {
      margin: 0 auto;
    }
  }
}
</style>

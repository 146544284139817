<template>
  <div class="answerWrap">
    <div class="answerBtn" @click="upOrDown">
      <i class="leftIcon"
        ><img src="../../assets/images/icon_dp.png" alt=""
      /></i>
      答题卡
      <i class="el-icon-arrow-up rightIcon" v-if="!answerShow"></i>
      <i class="el-icon-arrow-down rightIcon" v-else></i>
    </div>
    <div class="answerList" v-if="answerShow">
      <div class="ansQue" v-if="type">
        <div v-for="(ite, inde) in answerData" :key="inde">
          <h2 class="fontSize16">{{ ite.title }}</h2>
          <div
            style="display: inline"
            v-for="(item, index) in ite.timu"
            :key="index"
          >
            <template v-if="!item.list">
              <em
                :class="{
                  checkColor:
                    ((item.rtype == 1 || item.rtype == 5) && item.radio) ||
                    ((item.rtype == 2 || item.rtype == 3) &&
                      item.radio.length > 0) ||
                    (item.rtype == 4 && item.radio),
                }"
                @click="jump(item.question_seq)"
              >
                {{ item.question_seq }}
              </em>
              <i v-if="(index + 1) % 5 == 0"></i>
            </template>
            <template v-if="item.list">
              <strong
                v-for="(item1, index1) in item.list"
                :key="index1"
                style="font-weight: normal"
              >
                <em
                  :class="{
                    checkColor:
                      ((item1.rtype == 1 || item1.rtype == 5) && item1.radio) ||
                      ((item1.rtype == 2 || item1.rtype == 3) &&
                        item1.radio.length > 0) ||
                      (item1.rtype == 4 && item1.radio),
                  }"
                  @click="jump(item1.question_seq)"
                >
                  {{ item1.question_seq }}
                </em>
                <i v-if="(index1 + 1) % 5 == 0"></i>
              </strong>
            </template>
          </div>
        </div>
      </div>
      <div class="ansQue" v-else>
        <span v-for="(item, index) in answerData" :key="index">
          <template v-if="!item.list">
            <em
              :class="{
                checkColor:
                  ((item.rtype == 1 || item.rtype == 5) && item.radio) ||
                  ((item.rtype == 2 || item.rtype == 3) &&
                    item.radio.length > 0) ||
                  (item.rtype == 4 && item.radio),
              }"
              @click="jump(item.question_seq)"
            >
              {{ item.question_seq }}
            </em>
            <i v-if="(index + 1) % 5 == 0"></i>
          </template>
          <template v-if="item.list">
            <strong
              v-for="(item1, index1) in item.list"
              :key="index1"
              style="font-weight: normal"
            >
              <em
                :class="{
                  checkColor:
                    ((item1.rtype == 1 || item1.rtype == 5) && item1.radio) ||
                    ((item1.rtype == 2 || item1.rtype == 3) &&
                      item1.radio.length > 0) ||
                    (item1.rtype == 4 && item1.radio),
                }"
                @click="jump(item1.question_seq)"
              >
                {{ item1.question_seq }}
              </em>
              <i v-if="(index1 + 1) % 5 == 0"></i>
            </strong>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answerTotal: Array, 
    type: Boolean,
  },
  watch: {
    answerTotal: {
      handler() {
        this.answerData = [];
        this.answerTotal.forEach((item) => {
          this.answerData.push(item);
          // if (item.stuff_id == 0) {
          //   this.answerData.push(item);
          // } else {
          //   item.list.forEach((i) => {
          //     this.answerData.push(i);
          //   });
          // }
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      answerShow: false,
      answerData: [],
    };
  },
  mounted() {},
  methods: {
    upOrDown() {
      this.answerShow = !this.answerShow;
    },
    jump(data) {
      let t = document.querySelector("#question" + data).offsetTop;
      window.scrollTo(0, t - 88);
    },
  },
};
</script>

<style scoped lang=""></style>

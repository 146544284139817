<template>
  <!-- 论述 -->
  <div class="discussWrap" :id="'question' + list.question_seq">
    <b class="itemNum">{{ list.question_seq }}</b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
        <span class="detailMate" v-if="is_stuff == 1">资料</span>
        <span class="detailCate">论述题</span>
          <span class="detailScore" v-if="list.score">[{{ list.score }}分]</span></div>
        <div class="detailContent" v-html="list.title"></div>
      </div>
      <div class="detailOption">
        <div class="optionWrap">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="list.radio"
            class="mb20"
          ></el-input>
          <el-upload
            :data="upld"
            action="/api/uploader/v1/upload_file"
            list-type="picture-card"
            :headers="{
                        'Uxb-User-Agent':
                          'webVersion:v1.0.0',
                      }"
            :limit="9"
            accept=" .jpg, .png, .jpeg"
            :file-list="list.user_pic"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="sucesspic"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
          >
            <i class="el-icon-plus"></i>
            <br />
            <span>添加图片</span>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </div>
      <!--      <Point :thought_dial="list.thought_dial" v-if="list.thought_dial"></Point>-->
    </div>
    <i
      class="el-icon-star-on itemGreyStar"
      v-if="list.iscollect == 0"
      @click="collect(0)"
    ></i>
    <i
      class="el-icon-star-on itemYellowStar"
      v-if="list.iscollect == 1"
      @click="collect(1)"
    ></i>
  </div>
</template>

<script>
import { updatecollect, delcollect } from "../assets/api";
import { changeStr, pinjie, objKeySort } from '../../../../../assets/js/sign'
import Md5 from 'md5'
// import Point from "./com/Point.vue"; //思路点拨
export default {
  props: {
    list: Object,
    is_stuff: Number,
  },
  components: {},
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      upld: {
        siteid: JSON.parse(window.sessionStorage.getItem("infos")).siteid,
        bucket_type: 2,
        up_config: 1,
        uploadfiletype: 1,
        operation_mode:1,
        stime: '',
        sign: ''
      },
    };
  },

  mounted() {},
  methods: {
    collect(type) {
      if (type == 0) {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 1,
          question_paper_id: this.list.question_id,
        };
        updatecollect(params).then((res) => {
          if (res.code == 0) {
            this.list.collect_id = res.data.collect_id;
            this.list.iscollect = 1;
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          } else {
            this.$message.error("收藏失败");
          }
        });
      } else {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 1,
          collect_id: this.list.collect_id,
        };
        delcollect(params).then((res) => {
          if (res.code == 0) {
            this.list.collect_id = 0;
            this.list.iscollect = 0;
            this.$message({
              message: "取消收藏成功",
              type: "success",
            });
          } else {
            this.$message.error("取消收藏失败");
          }
        });
      }
    },
    //上传图片
    sucesspic(response) {
      this.list.user_pic.push({
        url: response.data.inline_url,
        path: response.data.uuid,
      });
    },
    handleRemove(file, fileList) {
      if (this.list.chang_img) {
        this.list.chang_img.push(file.path);
      } else {
        this.$set(this.list, "chang_img", []);
        this.list.chang_img.push(file.path);
      }
      this.list.user_pic.forEach((item, index) => {
        if (file.url == item.url) {
          this.list.user_pic.splice(index, 1);
        }
      });
    },
    handlePictureCardPreview(file, fileList) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 文件上传的状态
    beforeUpload(file, fileList) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJPG) {
        this.$message.warning('"您只能上传以下类型文件：.jpg, .png, .jpeg!"', 'error')
          return false
      }
      const isLimit = file.size / 1024 / 1024;
      if (isLimit > 10) {
        this.$message.warning("文件大小不得超过10M");
        return false;
      }
      this.upld.stime =''
      this.upld.sign =''
      this.upld.stime = parseInt(
              new Date().getTime() / 1000
      )
      this.upld.sign = Md5(
              changeStr(pinjie(objKeySort(this.upld)))
      )
    },
    // 文件上传限制
    handleExceed(files, fileList) {
      this.$message.warning(`最多上传 ${fileList.length} 个文件`);
    },
  },
};
</script>

<style scoped lang=""></style>
